<template>
  <div id="app">
    <!-- <router-view class="router-view"
                 v-slot="{ Component }">
      <transition :name="transitionName">
        <component :is="Component"
                   :key="$route.name" />
      </transition>
    </router-view> -->

    <router-view class="router-view" v-slot="{ Component }">
      <keep-alive>
        <component
          :is="Component"
          :key="$route.name"
          v-if="$route.meta.keepAlive"
        />
      </keep-alive>
      <component
        :is="Component"
        :key="$route.name"
        v-if="!$route.meta.keepAlive"
      />
    </router-view>

    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view> -->
    <!-- <singerRAll></singerRAll> -->
    <tabber></tabber>
  </div>
</template>

<script>
import { onBeforeUpdate, onMounted, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import tabber from "@/components/tabber";
// import singerRAll from "@/components/singerRAll";
import { getRouterStore } from "@/store";
// import { signalR } from "./signalR.ts";
import { logout } from "@/api/setting.js";
export default {
  components: {
    tabber,
    // singerRAll,
    // signalR,
  },
  setup() {
    let router = useRouter();
    // let route = useRoute();
    // let routerTag = ref(false)
    const state = reactive({
      transitionName: "slide-left",
    });
    router.beforeEach((to, from) => {
      console.log("start");

      // console.log(signalR, 70);

      // signalR.off("ForceOffline");
      // signalR.on("ForceOffline", async (data) => {
      //   console.log("强制下线", data);
      //   await signalR.stop();

      //   // await getAPI(SysAuthApi).apiSysAuthLogoutPost();
      //   await logout();
      //   localStorage.removeItem("user");
      //   router.replace("/login");
      // });
      // console.log(router, '啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊');
      if (to.meta.index > from.meta.index) {
        state.transitionName = "slide-left"; // 向左滑动
      } else if (to.meta.index < from.meta.index) {
        // 由次级到主级
        state.transitionName = "slide-right";
      } else {
        state.transitionName = ""; // 同级无过渡效果
      }

      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      window.scroll(0, 0);

      // document.body.addEventListener('touchmove', function (e) {
      //   e.preventDefault()
      // }, { passive: false })
    });
    onMounted(() => {
      console.log(router, "222222222222");
      // routerTag.value = route.meta.keepAlive
      // console.log(route, '1');
      // console.log(route.meta.keepAlive, '1');
      // console.log(route.meta.value.keepAlive, '1');
      // console.log(router, '2');
      // 在线用户列表
      // console.log(signalR, 70);
      // signalR.off("ForceOffline");
      // signalR.on("ForceOffline", async (data) => {
      //   console.log("强制下线", data);
      //   await signalR.stop();
      //   // await getAPI(SysAuthApi).apiSysAuthLogoutPost();
      //   await logout();
      //   localStorage.removeItem("user");
      //   router.replace("/login");
      // });
      // var str = navigator.userAgent;
      // var ipad = str.match(/(iPad).*OS\s([\d_]+)/);
      // var isIphone = !ipad && str.match(/(iPhone\sOS)\s([\d_]+)/);
      // var isAndroid = str.match(/(Android)\s+([\d.]+)/);
      // // 或者单独判断iphone或android
      // if (isIphone) {
      //   // router.replace("/PageMissing");
      // } else if (isAndroid) {
      // } else {
      //   router.replace("/PageMissing");
      // }
    });
    onBeforeUpdate(() => {});
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less">
// html,
// body {
//   height: 100vh;
//   // overflow-x: hidden;
//   // overflow-y: scroll;
//   // overflow: hidden;
// }
// body:before {
//   width: 100%;
//   height: 100%;
//   content: '';
//   position: fixed;
//   z-index: -1;
//   top: 0;
//   left: 0;
//   background: #fff;
// }
#app {
  height: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #333;
}
//  position: absolute 影响布局
.router-view {
  width: 100%;
  height: auto;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 500ms;
  position: absolute;
  backface-visibility: hidden;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.van-badge--fixed {
  z-index: 1000;
}
</style>
