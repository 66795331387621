import axios from "../utils/request";

//校验用户密码
export function checkPayPassword(params) {
  return axios.post("/api/user/checkPayPassword", params);
}
//修改支付密码
export function changePayPassword(params) {
  return axios.post("/api/user/changePayPassword", params);
}
//修改用户密码
export function changePassword(params) {
  return axios.post("/api/user/changePassword", params);
}
//转出积分
export function transferOutPoints(params) {
  return axios.post("/api/user/transferOutPoints", params);
}
//退出登录
export function logout() {
  return axios.post("/api/userAuth/logout");
}
// 我的资料
export function MyProfile() {
  return axios.get(`/api/user/MyProfile`);
}