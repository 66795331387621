import { createRouter, createWebHistory } from "vue-router";
const router = createRouter({
  history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/page/HomeView.vue"),
      //  import(/* webpackChunkName: "home" */ "@/views/goods/index.vue"),
      meta: {
        index: 1,
        title: "桃花系统",
        keepAlive: false,
        tabberIndex: 1,
      },
    },
    {
      path: "/page",
      name: "page",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/page/index.vue"),
      meta: {
        title: "视频详情",
        keepAlive: false,
      },
    },
    {
      path: "/pageIndex",
      name: "pageIndex",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "@/components/DplayerA/indexA.vue"
        ),
      meta: {
        title: "视频详情",
        keepAlive: false,
      },
    },
    {
      path: "/pageVideo",
      name: "pageVideo",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/page/pageVideo.vue"),
      meta: {
        title: "视频详情",
        keepAlive: false,
      },
    },
    {
      path: "/Video",
      name: "Video",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/video/index.vue"),
      meta: {
        index: 1,
        title: "视频",
        keepAlive: false,
        tabberIndex: 1,
      },
    },
    {
      path: "/user",
      name: "user",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/user/index.vue"),
      meta: {
        index: 1,
        title: "桃花系统",
        keepAlive: false,
        tabberIndex: 1,
      },
    },
    {
      path: "/register",
      name: "register",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/register/index.vue"),
      meta: {
        title: "注册",
        keepAlive: false,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/login/index.vue"),
      meta: {
        index: 1,
        title: "登录",
        keepAlive: false,
      },
    },
    {
      path: "/logincallback",
      name: "logincallback",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "@/views/logincallback/index.vue"
        ),
      meta: {
        title: "处理微信登录回调",
        keepAlive: false,
        index: 1,
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () =>
        import(
          /* webpackChunkName: "error" */ "@/views/privacyAgreement/index.vue"
        ),
      meta: {
        index: 2,
        title: "隐私协议",
        keepAlive: false,
      },
    },
    {
      path: "/serviceAgreement",
      name: "serviceAgreement",
      component: () =>
        import(
          /* webpackChunkName: "error" */ "@/views/serviceAgreement/index.vue"
        ),
      meta: {
        index: 2,
        title: "业务展开协议",
        keepAlive: false,
      },
    },
    {
      path: "/PageMissing",
      name: "PageMissing",
      component: () =>
        import(
          /* webpackChunkName: "error" */ "@/components/PageMissing/index.vue"
        ),
      meta: {
        index: 2,
        title: "错误页",
        keepAlive: false,
      },
    },
    {
      path: "/error",
      name: "error",
      component: () =>
        import(
          /* webpackChunkName: "error" */ "@/components/cannotAccess/index.vue"
        ),
      meta: {
        index: 2,
        title: "网站暂未开放",
        keepAlive: false,
      },
    },
    {
      path: "/detail",
      name: "detail",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/detail/index.vue"),
      meta: {
        index: 1,
        title: "商品详情",
        keepAlive: false,
      },
    },
    {
      path: "/checkout",
      name: "checkout",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/checkout/index.vue"),
      meta: {
        index: 1,
        title: "确认订单 ",
        keepAlive: false,
      },
    },
    {
      path: "/orderDetail",
      name: "orderDetail",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/orderDetail/index.vue"),
      meta: {
        index: 1,
        title: "订单详情",
        keepAlive: false,
      },
    },
    {
      path: "/orderlist",
      name: "orderlist",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/order/index.vue"),
      meta: {
        index: 1,
        title: "我的订单",
        keepAlive: false,
      },
    },
    {
      path: "/Myrights",
      name: "Myrights",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/Myrights/index.vue"),
      meta: {
        index: 1,
        title: "我的权益",
        keepAlive: false,
      },
    },
    {
      path: "/IneffectInfo",
      name: "IneffectInfo",
      component: () =>
        import(
          /* webpackChunkName: "error" */ "@/components/MyrightsTab/IneffectInfo.vue"
        ),
      meta: {
        index: 2,
        title: "生效中",
        keepAlive: false,
      },
    },
    {
      path: "/PendingeffectivenessInfo",
      name: "PendingeffectivenessInfo",
      component: () =>
        import(
          /* webpackChunkName: "error" */ "@/components/MyrightsTab/PendingeffectivenessInfo.vue"
        ),
      meta: {
        index: 2,
        title: "待生效",
        keepAlive: false,
      },
    },
    {
      path: "/ExpiredInfo",
      name: "ExpiredInfo",
      component: () =>
        import(
          /* webpackChunkName: "error" */ "@/components/MyrightsTab/ExpiredInfo.vue"
        ),
      meta: {
        index: 2,
        title: "已过期",
        keepAlive: false,
      },
    },
    {
      path: "/setting",
      name: "setting",
      component: () =>
        import(/* webpackChunkName: "address" */ "@/views/setting/index.vue"),
      meta: {
        index: 2,
        title: "我的资料",
        keepAlive: false,
      },
    },
    {
      path: "/ProductDetails",
      name: "ProductDetails",
      component: () =>
        import(/* webpackChunkName: "address" */ "@/views/ProductDetails/index.vue"),
      meta: {
        index: 2,
        title: "商品详情",
        keepAlive: false,
      },
    },
    // {
    //   path: "/goods",
    //   name: "goods",
    //   component: () =>
    //     import(/* webpackChunkName: "home" */ "@views/goods/index.vue"),
    //   meta: {
    //     index: 1,
    //     title: "桃花系统",
    //     keepAlive: false,
    //     tabberIndex: 1,
    //   },
    // },
  ],
  scrollBehavior(to, from) {
    return { top: from.meta.scrollTop, left: 0 };
  },
});
router.beforeEach(async (to) => {
  to.meta.scrollTop = document.documentElement.scrollTop
    ? document.documentElement.scrollTop
    : document.body.scrollTop;
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router;
