import { createApp } from "vue";

import {
  TreeSelect,
  Sidebar,
  SidebarItem,
  NavBar,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Divider,
  Popup,
  Overlay,
  Loading,
  Dialog,
  ContactCard,
  Form,
  AddressEdit,
  AddressList,
  Field,
  CellGroup,
  Cell,
  SwipeCell,
  Icon,
  Stepper,
  Card,
  Checkbox,
  CheckboxGroup,
  Button,
  Swipe,
  SwipeItem,
  PullRefresh,
  List,
  Tab,
  Tabs,
  SubmitBar,
  Tag,
  Tabbar,
  TabbarItem,
  Cascader,
  Switch,
  Sticky,
  ActionSheet,
  Skeleton,
  Image as VanImage,
  Lazyload,
  CountDown,
  RadioGroup,
  Radio,
  Col,
  Row,
  Notify,
  Picker,
  Step,
  Steps,
  Badge,
  NumberKeyboard,
  // SkeletonTitle,
  // SkeletonImage,
  // SkeletonAvatar,
  // SkeletonParagraph,
  Toast,
  Area,
  Grid,
  GridItem,

} from "vant";
import "vant/es/toast/style";
import App from "./App.vue";
import store from "./store";

import { createPinia } from "pinia";
// import { signalR } from "signalR";
import router from "./router";
import "lib-flexible/flexible";
import "vant/lib/index.css"; // 全局引入样式
import "@/assets/font/iconfont.css";
import "@/assets/font/iconfont.js";
import "@/assets/css/site.less";
// import 'video.js/dist/video-js.css'

// import Vue from "vue";
// import "vant/es/toast/style";
// import "vant/es/notify/style";
import Vconsole from "vconsole";

import initWxSdk from "weixin-js-sdk";
// import { wxInit } from "./utils/wxHelper";


export const app = createApp(App); // 创建实例
app.config.globalProperties.$toast = Toast;
// 全局过滤器
app.config.globalProperties.$filters = {
  prefix(url) {
    if (url && url.startsWith("http")) {
      return url;
    } else {
      url = `https://testygw.api.taohuasanbao.com/svc${url}`;
      return url;
    }
  },
};
const vConsole = new Vconsole();
// console.log(app,'Vue.prototype.$video = Video')
app.use(vConsole);
app
  .use(TreeSelect)
  .use(Sidebar)
  .use(SidebarItem)
  .use(NavBar)
  .use(ActionBarButton)
  .use(ActionBarIcon)
  .use(ActionBar)
  .use(Divider)
  .use(Popup)
  .use(Overlay)
  .use(Loading)
  .use(Dialog)
  .use(Toast)
  .use(ContactCard)
  .use(Form)
  .use(AddressEdit)
  .use(AddressList)
  .use(Field)
  .use(CellGroup)
  .use(Cell)
  .use(SwipeCell)
  .use(Icon)
  .use(Stepper)
  .use(Card)
  .use(Button)
  .use(Swipe)
  .use(SwipeItem)
  .use(PullRefresh)
  .use(List)
  .use(Tab)
  .use(Tabs)
  .use(SubmitBar)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Skeleton)
  .use(RadioGroup)
  .use(Radio)
  .use(Col)
  .use(Row)
  .use(Notify)
  .use(Picker)
  .use(Step)
  .use(Steps)
  .use(Badge)
  .use(NumberKeyboard)
  // .use(SkeletonTitle)
  // .use(SkeletonImage)
  // .use(SkeletonAvatar)
  // .use(SkeletonParagraph)
  .use(Tag)
  .use(Tabbar)
  .use(TabbarItem)
  .use(List)
  .use(Switch)
  .use(Sticky)
  .use(ActionSheet)
  .use(Cascader)
  .use(Area)
  .use(Grid)
  .use(GridItem)
  .use(Lazyload)
  .use(VanImage)
  .use(CountDown)
  .use(initWxSdk);

app.use(router);
app.use(store);
// wxInit();
app.mount("#app");
