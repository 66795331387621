<template>
  <div class="tabber">
    <van-tabbar
      v-model="active"
      active-color="rgba(255, 53, 111, 1)"
      v-if="$route.meta.tabberIndex"
      route
      :safe-area-inset-bottom="true"
    >
      <van-tabbar-item to="/home" name="home" replace>
        <span>首页</span>
        <template #icon="props">
          <img
            v-if="props.active"
            src="@/assets/img/paged.svg"
            alt="SVG Image"
            width="24"
            height="24  "
          />
          <img
            v-else
            src="@/assets/img/page.svg"
            alt="SVG Image"
            width="24"
            height="24  "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/video" name="video" replace>
        <span>视频</span>
        <template #icon="props">
          <img
            v-if="props.active"
            src="@/assets/img/usered.svg"
            alt="SVG Image"
            width="24"
            height="24  "
          />
          <img
            v-else
            src="@/assets/img/user.svg"
            alt="SVG Image"
            width="24"
            height="24  "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/user" name="user" replace>
        <span>我的</span>
        <template #icon="props">
          <img
            v-if="props.active"
            src="@/assets/img/usered.svg"
            alt="SVG Image"
            width="24"
            height="24  "
          />
          <img
            v-else
            src="@/assets/img/user.svg"
            alt="SVG Image"
            width="24"
            height="24  "
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const active = ref(0);
    const icon = {
      active: "https://fastly.jsdelivr.net/npm/@vant/assets/user-active.png",
      inactive:
        "https://fastly.jsdelivr.net/npm/@vant/assets/user-inactive.png",
    };
    return {
      icon,
      active,
    };
  },
};
</script>
<style lang="less" scoped>
.tabberIcon {
  width: 21px;
  height: 20px;
}
</style>
