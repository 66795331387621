import { defineStore } from "pinia";
import { store } from "@/store";
const state = {
  userMess: {
    phone: "", //手机号
    idCode: "", //身份码
    referrerIdCode: null, //上级用户身份码
    timeStamp: "",
    avatar: "", //头像
    account: "", //用户账户名
    token: "",
    refreshToken: "",
    addressChecked: false,
  },
  classDetail: "",
  address: {
    id: "", //地址id
    receiverName: "", //收货人姓名
    phone: "", //手机号
    province: "", //省份
    city: "", //城市
    district: "", //区
    addressDetail: "", //地址详情
    isDefault: true, //是否是默认地址
  },
};
export const userStore = defineStore("user", {
  state: () => state,
  getters: {
    userInfo: (state) => state,
  },
  actions: {
    // 获取值用导出的getRouterStore。wxLogin()方法
    // wxLogin() {
    //   return (state.type = 2222);
    // },
  },
  persist: true,
});
export function getRouterStore() {
  return userStore(store);
}
