import axios from "../utils/request";
//获取登录账户信息
export function getUserInfoList() {
  return axios.get(`/api/userAuth/userInfo`);
}
// 获取刷新Token
export function refreshToken(accessToken) {
  return axios.get(`/api/userAuth/refreshToken/${accessToken}`);
}

//登录
export function login(params) {
  return axios.post("/api/userAuth/login", params);
}

//微信登录回调
export function wechatAuthUrl() {
  return axios.get("/api/userAuth/wechatAuthUrl");
}

// 获取验证码
export function captcha() {
  return axios.get("/api/userAuth/captcha");
}
// 检查是否需要重置密码
export function needResetPassword() {
  return axios.get("api/userAuth/CheckNeedResetPassword");
}


