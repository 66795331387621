import axios from "axios";
import { Toast } from "vant";
import router from "../router/index.js";
import {refreshToken} from "../api/login.js";

import { app } from "../main.js";
import { getCurrentInstance, ref } from "vue";
import { getRouterStore } from "../store";
const currentInstance = getCurrentInstance();
const proxy = ref(currentInstance?.proxy);

const request = axios.create({
    baseURL: "http://aitao51.com:5005",
 //baseURL: "http://localhost:5005",
  // baseURL: "/api",
  timeout: 5000,
});

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(
  (config) => {
    var user = JSON.parse(localStorage.getItem("userInfo"));
console.log(user,getRouterStore().userMess.token,'getRouterStore')
    if (config && config.headers) {
      config.headers["Content-Type"] = "application/json;charset=utf-8";
    }
    if (user) {
      config.headers["Authorization"] = "Bearer " + user.accessToken; // 设置请求头
    }

    // if (userInfo && userInfo.token && userInfo.isLogin) {
    //   config.headers["Authorization"] = "Bearer " + user.token;
    // }
    // config.headers.shopid = 2;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
  (response) => {
    var user = JSON.parse(localStorage.getItem("userInfo"));
console.log(user,'getRouterStore')
    console.log(response,'49')
    let res = response.data;
    if (res.code === 200) {
      return Promise.resolve(res);
    }
    let index = res.message.indexOf("]");
    let result = res.message.substr(index + 1, res.message.length);
    console.log(res.message, index, res.message, "修改全局提示");
    // 如果是返回的文件
    if (res.code === 401) {
      if (router.currentRoute.path !== "/login") {
        console.log(user,59)
           Toast("请重新登录");
          router.replace({ path: "/login" });
        // if(user){
        //   refreshToken(user.accessToken)
        //   .then((res) => {
        //     if (res.code == 200) {
        //       localStorage.setItem("user", JSON.stringify(res.result));
        //       getRouterStore().userMess.token = res.result.accessToken;
        //       getRouterStore().userMess.refreshToken = res.result.refreshToken;
        //     } else {
        //       Toast(res.errors);
        //       router.replace({ path: "/login" });
        //     }
        //   })
        //   .catch((err) => {
        //     Toast("请重新登录");
        //     Toast(err);
        //     router.replace({ path: "/login" });
        //   });
        // }else{
        //   Toast("请重新登录");
        //   router.replace({ path: "/login" });
        // }
      }
      return Promise.reject(res.message);
    }
    if (res.code === 403) {
      Toast('请重新登录')
      if (router.currentRoute.path !== "/login") {
        localStorage.removeItem("user");
        router.replace({ path: "/login" });
      }
      // return Promise.reject(res.message);
    }
    if (res.code === 400) {
      // if(typeof res  === "string"){
      //   res = res ? JSON.parse(res) : res;
      // }
      Toast(result);
      // router.push({ path: "/error" });
      console.log(result, res.message, 72);
      return Promise.reject(result);
    }
    if (res.code === 404) {
      Toast(result);
      router.replace({ path: "/PageMissing" });
    }
    if (response.config.responseType === "blob") {
      return res;
    }
    // 兼容服务端返回的字符串数据
    if (typeof response === "string") {
      // console.log(res, "返回字符串");
      res = res ? JSON.parse(res) : res;
    }
    if (res.code === 500) {
      Toast(result);
      // router.replace({ path: "/PageMissing" });
      return Promise.reject(result);
    }
    return res;
  },
  (error) => {
    console.log(error,'拦截')
        // 如果是返回的文件
        if (error.code === 'ERR_NETWORK') {
          Toast.fail('服务端异常！')
          if (router.currentRoute.path !== '/login') {
            router.replace({ path: '/login' })
        }
          return Promise.reject(error);
        }
        if (error.code === 'ECONNABORTED') {
          Toast.fail('服务端异常')
          return Promise.reject(error);
        }
        if (error.response.status === 401) {
          // Toast.fail('服务端异常！')
          if (router.currentRoute.path !== '/login') {
              router.replace({ path: '/login' })
          }
          return Promise.reject(error.response.data);
        }
        if (error.response.status === 403) {
          // Toast.fail('服务端异常！')
          if (router.currentRoute.path !== '/login') {
              router.replace({ path: '/login' })
          }
          return Promise.reject(error.response.data);
        } 
        if (error.response.status === 406) {
          // Toast.fail('服务端异常！')
          if (router.currentRoute.path !== '/login') {
              router.replace({ path: '/login' })
          }
          return Promise.reject(error.response.data);
        } 
        if (error.response.status === 404) {
          Toast.fail(error.response.data.Message)
          // router.replace({ path: "/error" });
          return Promise.reject(error.response.data.Message);
        }
        if (error.response.status === 400) {
          let a = JSON.stringify(error.response.data.errors)
          console.log(a,117)
          if(a!==undefined){
            Toast(a)
          }else{
            Toast(error.response.data.Message)
          }
          // router.replace({ path: "/error" });
          
          return Promise.reject(error.response.data);
        }
        if (error.response.status === 500) {
          console.log(error.response.data.Message,132)
          // Toast.fail("服务端异常！");
          Toast(error.response.data.Message);
          // router.replace({ path: "/error" });
          return Promise.reject(error.response.data.Message);
        }
    // router.replace({ path: "/error" });
    // Toast.fail("服务端异常！");
    return Promise.reject(error);
  }
);

export default request;
